<template>
    <div class="pt-7 container-fluid" v-if="profile.superadmin">
        <b-card>
            <base-button type="success" @click="addNew" class="my--5">
                <i class="fas fa-plus"></i> {{$t('general.addnew')}}</base-button>
        </b-card>
        <b-card no-body v-if="users && users.data" class="p-4">
            <el-table class="table-responsive table" header-row-class-name="thead-light" :data="users.data" v-loading='loading' style="width: 100%" :empty-text="$t('general.empty_table')" :row-class-name="tableRowClassName">
                <el-table-column :label="$t('general.name')" prop="name" min-width="160">
                </el-table-column>

                <el-table-column :label="$t('general.email')" prop="email" min-width="240">
                </el-table-column>

                <el-table-column :label="$t('general.phone')" prop="phone" min-width="160">
                </el-table-column>

                <el-table-column :label="$t('general.status')" prop="status" min-width="140">
                    <template v-slot="{row}">
                        <badge class="badge-dot mr-4" type="">
                            <i :class="`bg-${status[row.status]}`"></i>
                            <span class="status" :class="`text-${status[row.status]}`">{{row.status?$t('general.active'):$t('general.passive')}}</span>
                        </badge>
                    </template>
                </el-table-column>
                <el-table-column align="right" min-width="180">
                    <template slot-scope="scope">
                        <base-button type="primary" size="sm" @click="handleEdit(scope.row)">{{$t('general.edit')}}</base-button>
                        <base-button type="danger" size="sm" @click="handleDelete(scope.row.uuid)">{{$t('general.delete')}}</base-button>
                    </template>
                </el-table-column>
            </el-table>
            <b-card-footer class="py-4 d-flex justify-content-end" v-if="users.pages>1&&!loading">
                <base-pagination v-model="pagination.page" :per-page="pagination.limit" :total="users.total" @change="changePage"></base-pagination>
            </b-card-footer>
        </b-card>

        <modal :show.sync="modal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="modalClose">
            <validation-observer v-slot="{handleSubmit, invalid}" ref="formValidator">
                <h6 slot="header" class="modal-title">{{modal.data.uuid ? $t('user.edit') : $t('user.add')}}</h6>
                <hr class="my-2">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)" v-loading="modal.loading">
                    <b-row>
                        <b-col md="6">
                            <label class="form-control-label">{{$t('general.status')}}</label>
                            <b-form-checkbox v-model="modal.data.status" class="switchCheck" name="check-button" switch>
                                {{modal.data.status ? $t('general.active') : $t('general.passive')}}
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6">
                            <label class="form-control-label">{{$t('general.superadmin')}}</label>
                            <b-form-checkbox v-model="modal.data.superadmin" class="switchCheck" name="check-button" switch>
                                {{modal.data.superadmin ? $t('general.active') : $t('general.passive')}}
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <base-input type="text" name="name" :nameAs="$t('profile.name_lastname')" :label="$t('profile.name_lastname')" :placeholder="$t('profile.name_lastname')" v-model="modal.data.name" required>
                    </base-input>
                    <base-input type="text" name="phone" :nameAs="$t('profile.phone')" :label="$t('profile.phone')" :placeholder="$t('profile.phone')" v-model="modal.data.phone" required>
                    </base-input>
                    <base-input type="email" name="email" :nameAs="$t('profile.email')" :label="$t('profile.email')" :placeholder="$t('profile.email')" v-model="modal.data.email" required>
                    </base-input>
                    <base-input type="password" name="password" :label="$t('profile.password')" :placeholder="$t('profile.password')" v-model="modal.data.password">
                    </base-input>
                    <small v-if="modal.data.uuid">{{$t('profile.pass_desc')}}</small>
                    <hr>
                    <b-alert show variant="warning" v-if="invalid">
                        <strong>{{$t('warning.fill_title')}} : </strong> {{$t('warning.fill')}}
                    </b-alert>
                    <base-button type="primary" native-type="submit" :disabled="modal.loading || invalid">{{$t('save')}}</base-button>
                    <base-button type="link text-danger" class="ml-auto float-right" @click="modal.status = false" :disabled="modal.loading">{{$t('close')}}</base-button>
                </b-form>
            </validation-observer>
        </modal>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import { USER_ALL, USER_UPDATE, USER_ADD, USER_DELETE } from '@/store/modules/define';
import { handleError } from '@/utils/func';
let self;
let emptyModal = {
    status: true,
    superadmin: false,
    name: '',
    email: '',
    phone: ''
};
export default {
    computed: {
        ...mapGetters({ profile: 'getProfile' }),
        ...mapState({
            users: state => state.user.users
        })
    },
    data() {
        return {
            modal: {
                loading: false,
                status: false,
                data: _.clone(emptyModal)
            },
            loading: true,
            status: {
                true: 'success',
                false: 'danger'
            },
            pagination: {
                limit: 10,
                page: 1
            },
            filter: {}
        };
    },
    methods: {
        tableRowClassName({ row, rowIndex }) {
            if (!row.status) {
                return 'table-danger';
            }
            return '';
        },
        changePage(val) {
            self.loading = true;
            self.pagination.page = val;
            self.getUsers();
        },
        handleEdit(row) {
            self.modal.loading = false;
            self.modal.status = true;
            self.modal.data = _.clone(row);
        },
        handleDelete(uuid) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.delete_info'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                confirmButtonText: self.$i18n.t('quest.delete'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(USER_DELETE, uuid)
                        .then(resp => {
                            self.loading = false;
                            self.getUsers();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        modalClose() {
            self.modal.loading = false;
            self.modal.status = false;
            self.modal.data = _.clone(emptyModal);
        },
        addNew() {
            self.modal.loading = false;
            self.modal.status = true;
            self.modal.data = _.clone(emptyModal);
        },
        onSubmit() {
            if (self.modal.data.uuid != undefined) {
                self.onUpdate();
            } else {
                self.onAdd();
            }
        },
        onAdd() {
            let user = _.pickBy(self.modal.data, (item, key) => {
                if (typeof item == 'boolean') {
                    return true;
                }

                return item && item.trim() != '';
            });

            if (user.password == undefined || (user.password && user.password.length < 6)) {
                self.$notify({ type: 'warning', message: self.$i18n.t('error.password_length') });
                return false;
            }

            self.modal.loading = true;
            self.$store
                .dispatch(USER_ADD, user)
                .then(resp => {
                    self.modalClose();
                    self.getUsers();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
        onUpdate() {
            let uuid = self.modal.data.uuid;
            let user = _.pickBy(self.modal.data, (item, key) => {
                if (typeof item == 'boolean') {
                    return true;
                }

                return item && item.trim() != '' && key != 'uuid';
            });

            if (user.password && user.password.length < 6) {
                self.$notify({ type: 'warning', message: self.$i18n.t('error.passwordstrength') });
                return false;
            }

            self.modal.loading = true;
            self.$store
                .dispatch(USER_UPDATE, { uuid, user })
                .then(resp => {
                    self.modal.loading = false;
                    self.getUsers();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
        getUsers() {
            self.$store
                .dispatch(USER_ALL, { pagination: self.pagination, filter: self.filter })
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
        }
    },
    watch: {
        profile: function(n, o) {
            if (n.superadmin == undefined || !n.superadmin) {
                self.$router.push({ name: 'permissionDenied' });
            }
        }
    },
    created() {
        self = this;
        self.getUsers();
        if (self.profile.uuid != undefined) {
            if (self.profile.superadmin == undefined || !self.profile.superadmin) {
                self.$router.push({ name: 'permissionDenied' });
            }
        }
    }
};
</script>
